document.addEventListener('turbolinks:load', function() {
  let costCostTypeId = document.getElementById('cost_cost_type_id');
  if (costCostTypeId) {
    if (costCostTypeId.value !== "1") {
      document.querySelector('.cost_vehicle').classList.add("d-none");
    }
    const costVehicle = document.querySelector('.cost_vehicle')
    costCostTypeId.addEventListener('change', function(evt) {
      var cost_type_id = evt.target.value;
      if (cost_type_id === "1") {
        costVehicle.classList.remove("d-none");
      } else {
        costVehicle.classList.add("d-none");
      }
    });
    costVehicle.addEventListener('change', function(evt) {
      const selectedOption = evt.target.options[evt.target.selectedIndex];
      const costPerKm = selectedOption.dataset.costPerKilometer;
      document.getElementById('cost_unit_cost').value = costPerKm;
    });
  }
});
