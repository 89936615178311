/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require("plugins/flatpickr.js.erb");
require("plugins/tom-select.js");
require("plugins/richtext.js");
require("jquery-treegrid/js/jquery.treegrid.min.js");
window.bootstrap = require("bootstrap");
import jsQR from 'jsqr';
window.jsQR = jsQR;
import 'expose-loader?bootbox!bootbox'
require("expose-loader?toastr!toastr");
require("src/company.js");
require("src/pagy.js.erb");
require("src/various.js");
require("src/simple_calendar.js");
require("src/acl_details.js");
require("src/offer_state_conditions.js");
require("src/asset_item_types.js");
require("src/asset_items.js");
require("src/assigned_tasks.js.erb");
require("src/billings.js");
require("src/leave_times.js");
require("src/attachments.js");
require("src/customers.js");
require("src/financial_reports.js");
require("src/job_times.js.erb");
require("src/pages.js");
require("src/projects.js");
require("src/quick_player.js");
require("src/requests.js.erb");
require("src/offer_products.js");
require("src/contacts.js.erb");
require("src/rooms.js");
require("src/schedules.js.erb");
require("src/tasks.js");
require("src/temporary_jobs.js");
require("src/offers.js");
require("src/costs.js");
require("src/google_maps.js.erb");
require("src/master_detail.js");
require("src/qr_code_scanner.js");
// expose to window
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts
// CSS
require("stylesheets/application.scss");
require("stylesheets/richtext.scss");
require.context('../images', true)
